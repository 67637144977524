import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/VideoView.css';
import VideoPlayer from '../../../../../../VideoPlayer';
import React, { useState, useEffect, useRef, useMemo, useCallback, forwardRef } from 'react';
import { fetchAlertFromApi } from '../../../../../../../api/beaverApi';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const fetchAlertWithRetry = async (alertId, retries = 0) => {
  try {
    return await fetchAlertFromApi(alertId);
  } catch (err) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch for alert ${alertId}, attempt ${retries + 1}`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchAlertWithRetry(alertId, retries + 1);
    }
    throw new Error(`Failed to fetch alert data after ${MAX_RETRIES} attempts: ${err.message}`);
  }
};

const VideoView = forwardRef(({ event, videoPlayerRef, onPlayerUpdate }, ref) => {
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [videoTime, setVideoTime] = useState(0);
  const [initialTime, setInitialTime] = useState(0);

  useEffect(() => {
    if (event.type === 'escalation' && event.alertId) {
      setLoading(true);
      fetchAlertWithRetry(event.alertId)
        .then((data) => {
          setVideoData({ ...data, VideoFileName: data.VideoFileName });
          setLoading(false);
        })
        .catch((err) => {
          setError('Failed to fetch alert data: ' + err.message);
          setLoading(false);
        });
    } else if (event.type === 'recordingVideo') {
      setVideoData({
        VideoFileName: event.fileName,
        BlobPath: event.blobPath,
        Duration: event.duration,
        TimeRecorded: event.timestamp,
      });
      setLoading(false);
    }
  }, [event]);

  const markers = useMemo(() => {
    if (videoData && videoData.RelativeTimestamp) {
      return [
        {
          time: videoData.RelativeTimestamp,
          label: videoData.Label,
        },
      ];
    }
    return [];
  }, [videoData]);

  const videoPlayerKey = useMemo(() => event.alertId || event.fileName, [event.alertId, event.fileName]);

  useEffect(() => {
    setInitialTime(videoTime);
  }, [event]);

  if (loading) {
    return <div className="event-detail-video-view-container">Loading...</div>;
  }

  if (error) {
    return <div className="event-detail-video-view-container">{error}</div>;
  }

  if (videoData && videoData.VideoFileName) {
    // console.log('alertData---------', alertData);
    return (
      <div className="event-detail-video-view-container">
        <div className="event-detail-video-player-container">
          <VideoPlayer
            videoFileName={videoData.VideoFileName}
            markers={markers}
            token={process.env.REACT_APP_BLOB_SAS_TOKEN}
            ref={videoPlayerRef}
            onPlayerUpdate={onPlayerUpdate}
            preStart={10}
            initialTime={initialTime}
            key={videoPlayerKey} // Add a key prop
          />
        </div>
      </div>
    );
  }

  return (
    <div className="event-detail-video-view-container">
      <p>Video is not available for this event</p>
    </div>
  );
});

export default React.memo(VideoView);
