import React, { useState, useEffect, useRef } from 'react';
import '../styles/MobilePanel.css';
import DeviceAssignment from './DeviceAssignment';
import SiteAssignment from './SiteAssignment'; // Add this import
import Card from './Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCompass, faShield, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'; // Add faMapMarkerAlt

const MobilePanel = ({
  userId,
  devices,
  alerts,
  handleDeviceClick,
  handleCardClick,
  config,
  activeDevice,
  NoAlertsMessage,
  isExpanded,
  onToggle,
  handleDeviceSelect,
  deviceShifts,
  updateDeviceAssignment,
  geofences, // Add this prop
  onSiteClick, // Add this prop
  organizationId, // Add this prop
  onGeofenceUpdate, // Add this prop
}) => {
  const [activeTab, setActiveTab] = useState('devices');
  const panelRef = useRef(null);
  const [isWarningFading, setIsWarningFading] = useState(false);

  const togglePanel = (tab) => {
    if (tab === activeTab && isExpanded) {
      onToggle(false);
    } else {
      onToggle(true);
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target) && isExpanded) {
        onToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpanded, onToggle]);

  return (
    <>
      <div ref={panelRef} className={`mobile-panel ${isExpanded ? 'expanded' : ''}`}>
        <div className="mobile-panel-tabs">
          <button className={`tab ${activeTab === 'devices' ? 'active' : ''}`} onClick={() => togglePanel('devices')}>
            {/* <FontAwesomeIcon icon={faCompass} />  */}
            Devices
          </button>
          <button className={`tab ${activeTab === 'sites' ? 'active' : ''}`} onClick={() => togglePanel('sites')}>
            {/* <FontAwesomeIcon icon={faMapMarkerAlt} />  */}
            Sites
          </button>
          <button className={`tab ${activeTab === 'alerts' ? 'active' : ''}`} onClick={() => togglePanel('alerts')}>
            {/* <FontAwesomeIcon icon={faBell} />  */}
            Alerts
          </button>
        </div>
        {isExpanded && (
          <div className="mobile-panel-content">
            {activeTab === 'devices' && (
              <DeviceAssignment
                handleDeviceClick={handleDeviceClick}
                devices={devices}
                deviceShifts={deviceShifts}
                updateDeviceAssignment={updateDeviceAssignment}
              />
            )}
            {activeTab === 'sites' && (
              <SiteAssignment
                geofences={geofences}
                devices={devices}
                deviceShifts={deviceShifts}
                onSiteClick={onSiteClick}
                organizationId={organizationId}
                onGeofenceUpdate={onGeofenceUpdate}
                handleDeviceSelect={handleDeviceSelect}
              />
            )}
            {activeTab === 'alerts' && (
              <div className="alerts-list">
                {alerts.length > 0 ? (
                  alerts.map((alert) => (
                    <Card
                      userId={userId}
                      key={alert.id}
                      initialCard={alert}
                      icon={config.transitionsConfig.find((t) => t.title === alert.Label)?.icon}
                      onDeviceSelect={handleDeviceSelect}
                      onCardClick={handleCardClick}
                    />
                  ))
                ) : (
                  <NoAlertsMessage />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MobilePanel;
