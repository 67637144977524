import React, { useState, memo, useEffect, useRef, useCallback } from 'react';
import '../styles/Transcript.css';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
const Transcript = memo(({ alert, getPlayerState, onTranscriptClick, userId, inShiftExpanded }) => {
  const [showTranscript, setShowTranscript] = useState(true);
  const transcriptContainerRef = useRef(null);
  const isOverwatch = userId === PLIX_OVERWATCH_ACCOUNT;
  const rawTranscripts = isOverwatch
    ? [...(alert.Transcripts || []), ...(alert.VideoTranscripts || [])] // Concatenate both arrays for Overwatch to get the pre-event transcript
    : alert.VideoTranscripts || alert.Transcripts || [];
  const [activeTranscriptId, setActiveTranscriptId] = useState(null);
  const lastScrolledTranscriptId = useRef(null);

  const transcripts = rawTranscripts.map((item, index) => ({
    ...item,
    relativeTimestamp: item.relativeTimestamp ?? 0,
    id: index,
  }));

  const formatTimestamp = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const isTranscriptActive = useCallback((currentItem, nextItem, currentTime) => {
    if (!nextItem) {
      return currentTime >= currentItem.relativeTimestamp;
    }
    return currentTime >= currentItem.relativeTimestamp && currentTime < nextItem.relativeTimestamp;
  }, []);

  const scrollToTranscript = useCallback((transcriptId) => {
    console.log('PLYR: Entering scrollToTranscript function');
    console.log(
      'PLYR: transcriptId=',
      transcriptId,
      'type:',
      typeof transcriptId,
      'lastScrolledTranscriptId.current=',
      lastScrolledTranscriptId.current,
      'transcriptContainerRef.current exists:',
      !!transcriptContainerRef.current
    );

    const transcriptElement = document.getElementById(`transcript-${transcriptId}`);
    console.log('PLYR: transcriptElement exists:', !!transcriptElement);

    if (transcriptId !== undefined && transcriptContainerRef.current && transcriptElement) {
      console.log('PLYR: Inside main if statement');
      console.log('PLYR: transcriptElement innerHTML:', transcriptElement.innerHTML);

      const container = transcriptContainerRef.current;
      const targetScrollTop = transcriptElement.offsetTop - container.offsetTop;

      console.log('PLYR: Scrolling to:', targetScrollTop);

      container.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth',
      });

      lastScrolledTranscriptId.current = transcriptId;
      console.log('PLYR: Scroll complete');
    } else {
      console.log('PLYR: Conditions not met for scrolling');
    }
  }, []);

  useEffect(() => {
    const updateActiveTranscript = () => {
      const { currentTime } = getPlayerState ? getPlayerState() : { currentTime: 0 };
      let activeTranscript = null;
      for (let i = transcripts.length - 1; i >= 0; i--) {
        if (transcripts[i].relativeTimestamp <= currentTime) {
          activeTranscript = transcripts[i];
          break;
        }
      }

      if (activeTranscript) {
        setActiveTranscriptId(activeTranscript.relativeTimestamp);
      }
    };

    const intervalId = setInterval(updateActiveTranscript, 1000);

    return () => clearInterval(intervalId);
  }, [getPlayerState, transcripts]);

  useEffect(() => {
    if (showTranscript && activeTranscriptId !== null) {
      scrollToTranscript(activeTranscriptId);
    }
  }, [activeTranscriptId, showTranscript, scrollToTranscript]);

  return (
    <div className={`alert-description ${inShiftExpanded ? 'in-shift-expanded' : ''}`}>
      {showTranscript && transcripts.length > 0 && (
        <div className="transcript-box" ref={transcriptContainerRef}>
          {transcripts.map((item, index) => {
            const nextItem = index < transcripts.length - 1 ? transcripts[index + 1] : null;
            const isActive = isTranscriptActive(item, nextItem, getPlayerState?.()?.currentTime || 0);
            return (
              <div
                key={index}
                id={`transcript-${item.relativeTimestamp}`}
                className={`transcript-line ${isActive ? 'active' : ''}`}
                onClick={() => onTranscriptClick(item.relativeTimestamp)}
              >
                <span className="transcript-timestamp">{formatTimestamp(item.relativeTimestamp)}</span>
                <p className="transcript-text">
                  <span className={item.isEscalation ? 'escalation-text' : ''}>{item.transcript}</span>
                </p>
              </div>
            );
          })}
        </div>
      )}
      {transcripts && transcripts.length > 0 && (
        <button className="view-transcript-button" onClick={() => setShowTranscript(!showTranscript)}>
          {showTranscript ? 'Hide Transcript' : 'View Transcript'}
        </button>
      )}
    </div>
  );
});

export default Transcript;
