import React, { useState, useEffect } from 'react';
// import Metrics from './Metrics/Metrics';
import ShiftReview from './ShiftReview/ShiftReview';
import '../../styles/NewDashboard/NewDashboard.css';
import { fetchAssignedToFromDeviceIds } from '../../api/beaverApi';
import { fetchDeviceConfigs, pingDevices } from '../../api/beaverApi';
import { toast } from 'react-toastify';
import { PLIX_OVERWATCH_ACCOUNT } from '../../utils/utilsEvents';
import { useDeviceFilter } from '../../contexts/DeviceFilterContext';
import { formatDistanceToNowStrict, subMinutes, format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';

const customLocale = {
  formatDistance: (token, count) => {
    const units = {
      xMinutes: `${count}m`,
      xHours: `${count}h`,
      xDays: `${count}d`,
      xMonths: `${count}mo`,
      xYears: `${count}y`,
    };
    return units[token] || token;
  },
};

const NewDashboard = ({ userId }) => {
  const [devices, setDevices] = useState([]);
  const [deviceAssignments, setDeviceAssignments] = useState({});
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [pingResults, setPingResults] = useState({});
  const [error, setError] = useState(null);
  const { selectedOrg } = useDeviceFilter();
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    const fetchDevicesAndPing = async () => {
      setIsLoadingDevices(true);
      try {
        const effectiveUserId = userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId;
        const pingedDevices = await pingDevices(effectiveUserId, true);

        if (pingedDevices && pingedDevices.length > 0) {
          const pingResultsMap = pingedDevices.reduce((acc, device) => {
            acc[device.deviceId] = device;
            return acc;
          }, {});
          setPingResults(pingResultsMap);

          // Set last updated time
          const now = new Date();
          const minutesSinceLastUpdate = now.getUTCMinutes() + (now.getUTCHours() % 3) * 60;
          const lastUpdateTime = subMinutes(now, minutesSinceLastUpdate % 5);
          setLastUpdated(lastUpdateTime);

          const deviceIds = pingedDevices.map((device) => device.deviceId);
          setDevices(deviceIds);

          // Fetch device assignments
          const assignments = await fetchAssignedToFromDeviceIds(deviceIds);
          setDeviceAssignments(assignments);
        } else {
          setDevices([]);
          toast.error('No devices found');
        }
      } catch (err) {
        console.error('Error fetching devices:', err);
        toast.error('Failed to fetch devices. Please try again later.');
        setError('Failed to fetch devices. Please try again later.');
      }
      setIsLoadingDevices(false);
    };

    fetchDevicesAndPing();

    // Update the "last updated" text every minute
    const intervalId = setInterval(() => {
      setLastUpdated((prevLastUpdated) => {
        if (prevLastUpdated) {
          return new Date(prevLastUpdated.getTime());
        }
        return null;
      });
    }, 60000);

    return () => clearInterval(intervalId);
  }, [userId, selectedOrg]);

  const formatLastUpdated = () => {
    if (!lastUpdated) return '';
    const distance = formatDistanceToNowStrict(lastUpdated, {
      unit: 'minute',
      roundingMethod: 'floor',
      locale: customLocale,
    });
    return distance === '0m' ? '0m ago' : `${distance} ago`;
  };

  const formatTooltipTime = () => {
    if (!lastUpdated) return '';
    return format(lastUpdated, 'h:mm aa');
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="newdashboard-container">
      <div className="newdashboard-header">
        <div className="newdashboard-header-left">
          <h1>Dashboard</h1>
          <span className="newdashboard-beta-tag">BETA</span>
        </div>
        <div className="newdashboard-header-right">
          <Tooltip title={`Updated at ${formatTooltipTime()}`} placement="bottom">
            <span>Updated {formatLastUpdated()}</span>
          </Tooltip>
        </div>
      </div>
      <div className="newdashboard-body">
        {/* <Metrics /> */}
        <ShiftReview
          devices={devices}
          userId={userId}
          deviceAssignments={deviceAssignments}
          isOverwatch={userId === PLIX_OVERWATCH_ACCOUNT}
          selectedOrg={selectedOrg}
          pingResults={pingResults}
          isLoadingDevices={isLoadingDevices}
        />
      </div>
    </div>
  );
};

export default NewDashboard;
