// src/components/AnnouncementPopup.js
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../styles/AnnouncementPopup.css';
import { faArrowRight, faHandPeace } from '@fortawesome/free-solid-svg-icons';
import { isWithinInterval, isPast, addDays, format } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';

const AnnouncementPopup = ({ announcement, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="announcement-popup-overlay">
      <div className="announcement-popup-content" ref={popupRef}>
        <button className="announcement-popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="announcement-popup-icon">
          <FontAwesomeIcon icon={announcement.icon} />
        </div>
        <div className="announcement-popup-text" dangerouslySetInnerHTML={{ __html: announcement.subtext }} />
      </div>
    </div>
  );
};

// Global variables for announcement
const TODAY = new Date('2024-10-22');
const WEEK_LENGTH = 7 * 24 * 60 * 60 * 1000;
const TWO_WEEKS_LATER = new Date(TODAY.getTime() + WEEK_LENGTH * 2);
const DEVICE_UPDATE_DATE = new Date('2024-10-30T10:00:00-07:00'); // 10 AM PST on October 28, 2024

const formatLocalDateTime = (date) => {
  return format(date, "EEEE, MMMM d 'at' h:mm a");
};

const ANNOUNCEMENTS = [
  // {
  //   text: 'Daily activity reports in your inbox',
  //   dateRange: [TODAY, TWO_WEEKS_LATER],
  //   link: '/settings#Reports',
  // },
  // {
  //   text: 'Export personnel and site activity to Excel',
  //   dateRange: [TODAY, TWO_WEEKS_LATER],
  //   link: '/settings#Reports',
  // },
  // {
  //   text: 'Audit trail for every video',
  //   dateRange: [TODAY, TWO_WEEKS_LATER],
  //   link: '/videos',
  // },
  {
    text: 'Device recording controls changing 10/30',
    dateRange: [new Date('2024-10-28'), new Date(new Date('2024-10-28').getTime() + WEEK_LENGTH)],
    link: 'two-finger',
    icon: faHandPeace,
    subtext: () => {
      const localUpdateDateTime = formatLocalDateTime(DEVICE_UPDATE_DATE);
      return `Your personnel ${isPast(DEVICE_UPDATE_DATE) ? 'received' : 'will receive'} a device update on ${localUpdateDateTime} with a change to how they record:<ul><li>Press and hold with 1 finger to start or end shift (no change).</li><li>Press and hold with 2 fingers to start or end recording.</li></ul>`;
    },
    excludedUserIds: ['auth0|669fed96e6d5fdc532aedf04'],
  },
];

function Announcements({ userId, isOverwatch }) {
  const location = useLocation();
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const { selectedOrg, setSelectedOrg, setDeviceFilter } = useDeviceFilter();
  const getEffectiveUserId = () => (isOverwatch ? selectedOrg : userId);

  useEffect(() => {
    const now = new Date();

    const validAnnouncements = ANNOUNCEMENTS.filter(
      (announcement) =>
        isWithinInterval(now, { start: announcement.dateRange[0], end: announcement.dateRange[1] }) &&
        (!announcement.excludedUserIds || !announcement.excludedUserIds.includes(getEffectiveUserId()))
    );

    if (validAnnouncements.length > 0) {
      const currentMinute = now.getMinutes();
      const randomIndex = currentMinute % validAnnouncements.length;
      setCurrentAnnouncement(validAnnouncements[randomIndex]);
    } else {
      setCurrentAnnouncement(null);
    }
  }, []);

  if (
    !currentAnnouncement ||
    (currentAnnouncement.link.startsWith(location.pathname) && location.pathname !== '/') ||
    location.pathname === '/videos'
  ) {
    return null;
  }

  const handleAnnouncementClick = (e) => {
    if (!currentAnnouncement.link.startsWith('/')) {
      e.preventDefault();
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="floating-announcement-banner">
        <Link to={currentAnnouncement.link} className="announcement-banner" onClick={handleAnnouncementClick}>
          <span className="new-label">NEW</span>
          {currentAnnouncement.text}
          <FontAwesomeIcon icon={faArrowRight} className="announcement-arrow" />
        </Link>
      </div>
      {showPopup && (
        <AnnouncementPopup
          announcement={{
            ...currentAnnouncement,
            subtext:
              typeof currentAnnouncement.subtext === 'function'
                ? currentAnnouncement.subtext()
                : currentAnnouncement.subtext,
          }}
          onClose={() => setShowPopup(false)}
        />
      )}
    </>
  );
}

export default Announcements;
