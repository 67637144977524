import { startOfDay, subDays, isToday, isYesterday, isThisWeek } from 'date-fns';
import { fetchLatestLocationEvent } from '../api/beaverApi';

const MIN_TIME_BETWEEN_EVENTS = 60;

export const getShiftOfflineTime = async (shift) => {
  // if shift.lastSeen is n/a then we fallback to shift.lastShiftTime then to null
  const lastSeen =
    shift.lastSeen && shift.lastSeen !== 'N/A'
      ? new Date(shift.lastSeen)
      : new Date(shift.lastShiftTime ? shift.lastShiftTime : null);
  const currentTime = new Date();

  // Fetch the latest location event
  const latestLocationEvent = await fetchLatestLocationEvent(shift.deviceId, currentTime.toISOString());
  // if latestLocationEvent is null, then no location event has been seen for the device
  const latestLocationTime = latestLocationEvent ? new Date(latestLocationEvent.enter_time) : null;
  // const latestLocationTime = null;

  // Determine the most recent time between lastSeen and latestLocationTime
  // if latestLocationTime is null, we want to use lastSeen
  // if lastSeen is null, we want to use latestLocationTime
  // if latestLocationTime is greater than lastSeen, we want to use latestLocationTime
  // if lastSeen is greater than latestLocationTime, we want to use lastSeen
  const mostRecentTime =
    latestLocationTime && (!lastSeen || latestLocationTime > lastSeen) ? latestLocationTime : lastSeen;

  if (mostRecentTime) {
    const timeDifference = currentTime.getTime() - mostRecentTime.getTime();
    const isOffline = timeDifference > 30 * 60 * 1000; // 30 minutes in milliseconds
    return { isOffline, offlineSince: mostRecentTime };
  }

  return { isOffline: false, offlineSince: null };
};

export const groupShiftsByDateRange = (shifts) => {
  const today = startOfDay(new Date());
  const yesterday = subDays(today, 1);
  return shifts.reduce(
    (acc, shift) => {
      const shiftDate = new Date(shift.startTime);
      if (isToday(shiftDate)) {
        acc.today.push(shift);
      } else if (isYesterday(shiftDate)) {
        acc.yesterday.push(shift);
      } else if (isThisWeek(shiftDate)) {
        acc.lastWeek.push(shift);
      } else {
        acc.older.push(shift);
      }
      return acc;
    },
    { today: [], yesterday: [], lastWeek: [], older: [] }
  );
};

export const getDaysDifference = (start, end) => {
  const startDate = new Date(start).setHours(0, 0, 0, 0);
  const endDate = new Date(end).setHours(0, 0, 0, 0);
  return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
};
