import { cosmosClient } from '../utils/eventstorage';

const database = cosmosClient.database('Alerts');
const container = database.container('StateMachineConfig');
const orgDbId = 'Organizations';
const orgDbContainerId = 'Organizations';
const orgDbContainer = cosmosClient.database(orgDbId).container(orgDbContainerId);

async function fetchRetentionPeriod(userId) {
  console.log('fetching retention period for user:', userId);
  try {
    const querySpec = {
      query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
      parameters: [{ name: '@userId', value: userId }],
    };
    const { resources: orgs } = await orgDbContainer.items.query(querySpec).fetchAll();
    if (orgs.length > 0) {
      const org = orgs[0];
      console.log('org:', org);
      if (org.retentionPeriodDays) {
        return org.retentionPeriodDays;
      }
    } else {
      console.log('No organization found for user:', userId);
      return 30;
    }
  } catch (error) {
    console.error('Error fetching retention period:', error);
  }
}

async function getConfig(userId) {
  try {
    const query = `SELECT * FROM c WHERE c.userId = '${userId}'`;
    const { resources: items } = await container.items.query(query).fetchAll();
    if (items.length === 0) {
      const defaultConfig = require('../config/securityGuard.json');
      defaultConfig.userId = userId;
      const { resource: createdItem } = await container.items.create(defaultConfig);
      return createdItem;
    }
    return items[0];
  } catch (error) {
    console.error('Error fetching config:', error);
    throw new Error('Failed to fetch or create config');
  }
}

async function updateConfig(userId, config) {
  try {
    const query = `SELECT * FROM c WHERE c.userId = '${userId}'`;
    const { resources: items } = await container.items.query(query).fetchAll();
    if (items.length > 0) {
      const item = items[0];
      return await container.item(item.id, item.userId).replace(config);
    }
  } catch (error) {
    console.error('Error updating config:', error);
    throw new Error('Failed to update config');
  }
}

export { getConfig, updateConfig, fetchRetentionPeriod };
